import React from 'react'
import Container from 'components/common/Container'
import { AboutWrapper, Details, Wrapper } from './styles'

export default () => {
  return (
    <Wrapper id="about">
      <AboutWrapper as={Container}>
        <Details>
          <h1>About us</h1>
          <p>
            In todays market there is a need for complete application services with a focus on operations and customers.
            IT is not part of the core business for most companies. These companies still have need of specific
            knowledge and experience in buying the necessary hardware, software, connectivity, security and backup
            solutions.
          </p>
          <p>
            Over the past years we have been involved in numerous data-center set-ups, migrations and cloud integrations.
          </p>
          <p>
            Cloud technology is no longer a commodity, it’s a strategic resource. We can work with you to figure out
            what your business needs, how to deploy new services and how your company can benefit most of
            well-established and innovative products and services.
          </p>
          <p>
            We do not work with fixed predefined solutions or a preferred vendor, but develop individual setups. No
            virtual support team, but a local Belgian company that can assist and support you and your customers on
            site.
          </p>
          <h2>Key benefits</h2>
          <ul>
            <li>Local, on-demand resources</li>
            <li>No hardware lock-in</li>
            <li>Cloud agnostic (Azure, AWS, Google, ...)</li>
            <li>Experienced architects, engineers, consultants, program managers and programmers</li>
          </ul>
        </Details>
      </AboutWrapper>
    </Wrapper>
  )
};
